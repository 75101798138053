import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0060 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="運送業の働き方改革！改革までに取り組むべき内容・注意点は？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.11.16</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        2024年4月1日から運送業においても時間外労働上限規制が適用されます。これまで運送業は業務の特殊性を勘案し、時間外労働の上限規制の適用が「猶予」されていました。そして、5年の期間を経て猶予期間が終了します。そこで、適用される内容、運送業として行わなければならないこと等にフォーカスをあて解説します。{' '}
      </p>
      <img src="/images/column/details/c0060.jpg" alt="キンクラコラム画像60" />
      <h1 id="toc1">2024年4月1日から適用される内容</h1>
      <p>
        建設業等と同様に時間外労働の「上限規制」が適用されることとなります。これは努力義務ではなく、「法律」によって、違反した場合には罰則を設け、時間外労働として命じることができる上限が課されるということになります。言い換えると、
        <span>上限を超えた時間外労働を命ずることができない</span>
        ということです。労働基準法第32条において、労働時間は1日8時間まで、1週間40時間までとされています。ただし、労使合意の元、「36協定」を締結することで1か月45時間、年360時間までであれば時間外労働を命じることが可能と解されます。また、多忙を極める場合に前述の上限時間を遵守できないという由々しき問題も想定されます。そこで、臨時的な事由がある場合は「特別条項」と言い、労使合意により
        <span>
          年間720時間、単月100時間未満（休日労働含む）、複数月（2～6か月いずれの月平均でも）80時間以下（休日労働含む）までであれば時間外労働を命じることが可能
        </span>
        となります。注意点として、
        <span>時間外労働が1か月45時間を超えることができるのは年6回まで</span>
        です。
      </p>

      <h1 id="toc2">改革までに取り組むべき内容等</h1>
      <p>
        運送業特有の問題として、手待ち時間の労働時間が挙げられます。そこで、
        <span>予約受付システムを導入し、荷待時間を減らす</span>
        ということです。配送先に到着しても同様の配送業者が一斉に到着してしまうと、待ち時間が発生しますので、その「ロスタイム」を削減するための取り組みですが、これは運送業者のみの努力では限界がありますので、荷受業者とも「双方協力型」の取り組み内容となります。併せて高速道路の有効活用です。特にトラックドライバーの労働時間の最も大部分を占めるのは移動時間です。そこで（もちろん近距離ではむしろ無駄になることもあるのでしょうが）
        <span>可能な限り高速道路を活用することで、時間圧縮が可能</span>
        となります。この点は会社としての予算も無視することはできませんが、2024年4月1日を契機に積極的に導入を検討する企業は増えています。次に市街地での納品業務です。特に都心部では道路幅員が狭い場合も多く、かつ、共働き世帯の増加も相まって、不在時の再配達に時間を要するケースが少なくありません。そこで、共同宅配ボックスの設置が可能であれば再配達の時間も圧縮することができます。
        <br />
        <br />
        最後に<span>新卒者へのアプローチも必要</span>
        です。業種全体として、高年齢化が進んでいる点は否めず、新卒者の入社割合も高い業種とは言えません。
        <span>
          新卒者が少ないということは一人のベテラン労働者が多くの業務を担うことになり、結果として時間外労働時間数が減らないということに繋がります。
        </span>
        また、時間外労働時間数が増えるごとに、健康問題にも波及します。そのことが引き金となり結果的に欠員が出てしまうと、他の労働者へのしわ寄せが起こり、悪循環となります。新卒者へどのようにアピールしていくかは細かい部分は会社ごとの判断にはなりますが、一般的は週休2日の徹底、年次有給休暇の積極的な取得実績の公表、給与体系の見直しという点は「処遇改善」という意味でも、業種に関わりなく重要なポイントです。特に給与については労働条件の中でも重要な内容であり、「応相談」という形では応募者目線では怪訝に見られてしまうことから透明性を図った形で明示すべきです。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">改革に向けて注意点等</h1>
      <p>
        取り組むべき内容に挙げた例は言うまでもなく、費用が発生します。ただし、時間外労働の上限規制によって、時間外労働時間数が減るのであれば当該人件費を費用に充てることが可能となります。また、働き方改革では「多様な働き方」の推進も掲げられています。もちろん同一労働同一賃金の問題も無視できませんが、例えばこれまで正社員のみの雇用しか行っていなかった場合、家庭の事情によりむしろ正社員を希望しない（短時間勤務等を望む）女性や高齢者などの採用を検討する企業も増えています。最後に運送業といっても、運行管理者、ドライバー等「企業内の職種」は様々です。個々にそれぞれ違った労務管理上の問題点は生じ得ますが、割増賃金や安全配慮義務等の考え方においては差異がありませんので、注意が必要です。特にドライバーについては顔を合わせる機会自体が少なく、
        <span>
          時間外労働上限規制の改正についても施行日から逆算して周知していかなければ浸透していくまでに時間を要してしまいます。
        </span>
      </p>

      <h1 id="toc4">最後に</h1>
      <p>
        運送業については一般職と同様の労務管理が馴染まないケースが多く存在します。ただし、2024年4月1日以降は一般職と同様の水準で時間外労働をその範囲内におさめなければなりません。よって、2024年4月1日から逆算して、まずは自社として対応可能なタスクの洗い出しを進めていく必要があります。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0060
